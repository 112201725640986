import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
    UserOutlined,
    SettingOutlined,
    DollarCircleOutlined,
    BarChartOutlined,
    ReadOutlined,
    ShopOutlined,
    PhoneOutlined,
    BellOutlined,
    TeamOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
    //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
    {
        exact: true,
        path: PUBLIC_ROUTE.ROOT,
        component: () => {
            if (localStorage.getItem("token")) {
                return <Redirect to="/home" />
            } else {
                return <Redirect to="/login" />
            }
        },
    },
    {
        exact: true,
        path: PUBLIC_ROUTE.LOGIN,
        component: loadable(() => import("../pages/auth/Login")),
    },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
    {
        exact: true,
        path: "/home",
        sidebar: {
            label: "홈피드",
        },
        component: loadable(() => import("../pages/home/Index")),
    },
    {
        exact: true,
        path: "/user",
        sub: false,
        sidebar: {
            label: "고객사 리스트",
        },
        children: [
            {
                exact: true,
                visible: false,
                path: "",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/:category",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/wait/detail/:id",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/use/register",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/use/detail/:id",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/withdrawal/detail/:id",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/detail/:id",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/modify/:id",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/pension/:id",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/pension/setting/:id",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/resignation/:id",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/resignation/detail/:id",
                component: loadable(() => import("../pages/user/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/resignation/modify/:id",
                component: loadable(() => import("../pages/user/List")),
            },
        ],
    },
    {
        exact: true,
        path: "/worker",
        sub: false,
        sidebar: {
            label: "고객사 입･퇴사자 리스트",
        },
        children: [
            {
                exact: true,
                visible: false,
                path: "",
                component: loadable(() => import("../pages/worker/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/detail/:id",
                component: loadable(() => import("../pages/worker/Detail")),
            },
            {
                exact: true,
                visible: false,
                path: "/user/:type/register",
                component: loadable(() => import("../pages/user/List")),
            },
        ],
    },
    {
        exact: true,
        path: "/work",
        sidebar: {
            label: "근로 정책 설정",
            sub: true,
            className: "bolder"
        },
        children: [
            {
                exact: true,
                visible: true,
                path: "/document",
                sidebar: {
                    label: "문서 설정",
                },
                component: loadable(() => import("../pages/work/document/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/document/history",
                component: loadable(() => import("../pages/work/document/List")),
            },
            {
                exact: true,
                visible: true,
                path: "/salary",
                sidebar: {
                    label: "급여 정책 설정",
                },
                component: loadable(() => import("../pages/work/salary/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/default",
                component: loadable(() => import("../pages/work/salary/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/default/modify",
                component: loadable(() => import("../pages/work/salary/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/default/log",
                component: loadable(() => import("../pages/work/salary/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/taxfree",
                component: loadable(() => import("../pages/work/salary/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/taxfree/modify/:id",
                component: loadable(() => import("../pages/work/salary/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/taxfree/register",
                component: loadable(() => import("../pages/work/salary/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/deduction",
                component: loadable(() => import("../pages/work/salary/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/salary/deduction/register",
                component: loadable(() => import("../pages/work/salary/List")),
            },
            {
                exact: true,
                visible: true,
                path: "/holiday",
                sidebar: {
                    label: "휴일 설정",
                },
                component: loadable(() => import("../pages/work/holiday/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/holiday/register",
                component: loadable(() => import("../pages/work/holiday/List")),
            },
            {
                exact: true,
                visible: true,
                path: "/rate",
                sidebar: {
                    label: "요율 설정",
                },
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/fourInsurance",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/fourInsurance/modify",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/fourInsurance/log",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/etc",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/etc/modify",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/business",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/business/modify",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/worker",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/worker/modify",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/worker/log",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/withholding",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/rate/withholding/modify",
                component: loadable(() => import("../pages/work/rate/List")),
            },
            {
                exact: true,
                visible: true,
                path: "/dayOff",
                sidebar: {
                    label: "연차 규정 설정",
                },
                component: loadable(() => import("../pages/work/dayOff/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/dayOff/modify",
                component: loadable(() => import("../pages/work/dayOff/List")),
            },
        ],
    },
    {
        exact: true,
        path: "/alarm",
        sub: false,
        sidebar: {
            label: "알림 메시지",
        },
        children: [
            {
                exact: true,
                visible: true,
                path: "",
                component: loadable(() => import("../pages/alarm/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/detail/:id",
                component: loadable(() => import("../pages/alarm/Detail")),
            },
            {
                exact: true,
                visible: false,
                path: "/register",
                component: loadable(() => import("../pages/alarm/Register")),
            },
        ],
    },
    {
        exact: true,
        path: "/admin",
        sub: false,
        sidebar: {
            label: "관리자 등록",
        },
        children: [
            {
                exact: true,
                visible: true,
                path: "",
                component: loadable(() => import("../pages/admin/List")),
            },
            {
                exact: true,
                visible: true,
                path: "/:type",
                component: loadable(() => import("../pages/admin/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/:type/detail/:id",
                component: loadable(() => import("../pages/admin/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/:type/modify/:id",
                component: loadable(() => import("../pages/admin/List")),
            },
            {
                exact: true,
                visible: false,
                path: "/:type/register",
                component: loadable(() => import("../pages/admin/List")),
            },
        ],
    },
    {
        exact: true,
        path: "/password/modify",
        component: loadable(() => import("../pages/auth/ChangePassword")),
    },

];
