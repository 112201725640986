import React, {useEffect, useState} from "react";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import {Button, Layout, Menu, message} from "antd";
import { RightOutlined } from "@ant-design/icons";

import { privateRoutes } from "../routes/routes";
import useSWR from "swr";

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const ServiceWrapper = ({ children }) => {
    const history = useHistory();

    const isAccountingManager = localStorage.getItem("is_accounting_manager") ? localStorage.getItem("is_accounting_manager") : ''
    const location = useLocation();

    const pathName = location.pathname;
    const rootPath = pathName.substring(0, pathName.indexOf("/", 2));
    const pathArray = pathName.split("/")

    const { data, mutate } = useSWR(`/admin/info`);
    const originalData = data?.data?.user_info;

    const openKey = [];
    openKey[0] = pathArray.length > 2 ? rootPath : pathName;
    if (pathArray.length > 3) {
        openKey[1] = '/' + pathArray[1] + '/' + pathArray[2]
    }
    if (openKey[0] === '/user') {
        openKey[0] = '/user/:type'
    }

    useEffect(() => {
        if (pathName && originalData) {
            if (isAccountingManager === '1') {
                if (pathName.includes('/user') === false && pathName.includes('/worker') === false) {
                    message.error('권한이 없는 페이지입니다.')
                    history.push('/user')
                }
            } else {
                if ((pathName.includes('/user') !== false || pathName.includes('/worker') !== false) && originalData?.is_admin_user_permission !== 1) {
                    message.error('권한이 없는 페이지입니다.')
                    history.push('/home')
                } else if (pathName.includes('/work') !== false && originalData?.is_admin_salary_permission !== 1) {
                    message.error('권한이 없는 페이지입니다.')
                    history.push('/home')
                } else if (pathName.includes('/alarm') !== false && originalData?.is_admin_push_permission !== 1) {
                    message.error('권한이 없는 페이지입니다.')
                    history.push('/home')
                } else if (pathName.includes('/admin') !== false && originalData?.is_admin_register_permission !== 1) {
                    message.error('권한이 없는 페이지입니다.')
                    history.push('/home')
                }
            }
        }
    }, [pathName, originalData]);

    return (
        pathName !== '/password/modify'
        ?
            <> <Layout>
                <div className="left-menu-box">
                    <div className="logo-box">
                        <img className="logo" src="/assets/logo.png" alt="" />
                    </div>
                    <Menu className="item-box"
                          mode="inline"
                          activeKey={location.pathname}
                          defaultOpenKeys={openKey}
                          selectedKeys={openKey}>
                        {privateRoutes.map((parentRoute) =>
                            ((isAccountingManager !== '1' && (
                                Object(parentRoute.sidebar).label === '홈피드' ||
                                ((originalData?.is_admin_user_permission && (Object(parentRoute.sidebar).label === '고객사 리스트' || Object(parentRoute.sidebar).label === '고객사 입･퇴사자 리스트')) ||
                                    (originalData?.is_admin_salary_permission && Object(parentRoute.sidebar).label === '근로 정책 설정') ||
                                    (originalData?.is_admin_push_permission && Object(parentRoute.sidebar).label === '알림 메시지') ||
                                    (originalData?.is_admin_register_permission && Object(parentRoute.sidebar).label === '관리자 등록'))
                            )) || (isAccountingManager === '1' && (Object(parentRoute.sidebar).label === '고객사 리스트' || Object(parentRoute.sidebar).label === '고객사 입･퇴사자 리스트'))) && (
                                    (Object(parentRoute.sidebar).label && (
                                            (
                                                (parentRoute.children && Object(parentRoute.sidebar).sub === true) ? (
                                                    <SubMenu
                                                        key={parentRoute.path}
                                                        icon={Object(parentRoute.sidebar).icon}
                                                        title={Object(parentRoute.sidebar).label}
                                                        className="sub-menu"
                                                    >
                                                        {parentRoute.children.map(
                                                            ({ visible = true, ...childrenRoute }) =>
                                                                childrenRoute?.sub ? (<SubMenu
                                                                        key={`${parentRoute.path}${childrenRoute.path}`}
                                                                        title={Object(childrenRoute.sidebar).label}
                                                                        className="sub-menu"
                                                                    >
                                                                        {childrenRoute.sub.map(({ visible = true, ...sub }) =>
                                                                                visible && (
                                                                                    <Menu.Item
                                                                                        key={`${parentRoute.path}${childrenRoute.path}${sub.path}`}
                                                                                        icon={Object(sub.sidebar).icon}>
                                                                                        <NavLink
                                                                                            to={`${parentRoute.path}${childrenRoute.path}${sub.path}`}
                                                                                            className="nav-text sub-menu">
                                                                                            {Object(sub.sidebar).label}
                                                                                        </NavLink>
                                                                                    </Menu.Item>
                                                                                )
                                                                        )}
                                                                    </SubMenu>)
                                                                    : (
                                                                        visible && (
                                                                            <Menu.Item
                                                                                key={`${parentRoute.path}${childrenRoute.path}`}
                                                                                icon={Object(childrenRoute.sidebar).icon}
                                                                            >
                                                                                <NavLinkTest
                                                                                    to={`${parentRoute.path}${childrenRoute.path}`}
                                                                                    className="nav-text"
                                                                                >
                                                                                    {Object(childrenRoute.sidebar).label}
                                                                                </NavLinkTest>
                                                                            </Menu.Item>
                                                                        )
                                                                    )
                                                        )}
                                                    </SubMenu>
                                                )  : (
                                                    <Menu.Item
                                                        key={parentRoute.path}
                                                        icon={Object(parentRoute.sidebar).icon}
                                                    >
                                                        <NavLink
                                                            to={parentRoute.path}
                                                            className="nav-text"
                                                        >
                                                            {Object(parentRoute.sidebar).label}
                                                        </NavLink>
                                                    </Menu.Item>
                                                )
                                            )
                                        )
                                    )
                                )
                        )}
                    </Menu>
                    <div className={'profile-box'}>
                        <div className={'title'}>
                            환영합니다. {originalData?.name ? originalData?.name + '님' : ''}
                        </div>
                        <div className={'title'}>
                            관리자 등급: {originalData ? ((originalData?.id === 1) ? '최고 관리자' : (originalData?.is_accounting_manager === 1 ? '회계 관리자' : '사이트 관리자')) : '-'}
                        </div>
                        <Button className={'primary-button full-select'} onClick={() => history.push('/password/modify')}>
                            비밀번호 변경
                        </Button>
                    </div>
                </div>
                <Layout
                    style={{
                        marginLeft: 260,
                        transition: "all 0.2s",
                        height: "100vh",
                        backgroundColor: "white",
                    }}
                >
                    <Content style={{ overflow: "initial" }}>
                        {children}
                    </Content>
                </Layout>
            </Layout>
            </>
        :
            <>
                <Layout>
                    <Content style={{ overflow: "initial" }}>
                        {children}
                    </Content>
                </Layout>
            </>
    );
};

/*const Menu = styled(Menu)`
    height: 85%;
    background: #EEF2F5;
    
    .ant-menu-item {
        color: #1795D3 !important; /!* Custom color for unselected menu items *!/
    }
    
    .ant-menu-item-selected {
        color: rgb(23, 149, 211) !important; /!* Ensure selected item text color *!/
    }

`;*/

const NavLinkTest = styled(NavLink)`
    font-size: 16px;
    font-weight: bolder;
    color: rgb(23, 149, 211) !important;
`;

export default ServiceWrapper;



const ProfileArea = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 18px;
    background: #EEF2F5;
`;
